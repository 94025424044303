import React from 'react';
import { Link, navigate } from 'gatsby';
import styled from '@emotion/styled';
import { view } from 'react-easy-state';
import { Switch } from 'antd';

import mainStore from '../stores';

// import logo from '../images/iaf-logo-light.svg';

// import { colors } from '../../tailwind';

const Container = styled.div`
  ${tw`pt-8 pb-4 flex flex-wrap items-center`};
`;
const StyledLink = styled(Link)`
  ${tw`no-underline text-inherit inline-block mr-4`};
`;
const Title = styled.h1`
  ${tw`flex flex-col leading-none text-2xl`}
  font-family: 'Roboto Condensed';
`;

class Header extends React.Component {
  state = {
    focused: false
  }

  submit = () => {
    if (mainStore.keyword.length > 0) {
      navigate(`/?q=${mainStore.keyword}`);
    } else {
      navigate('/');
    }
  }

  onKeywordChange = (ev) => {
    mainStore.keyword = ev.target.value;
  }

  onKeyPress = (ev) => {
    if (ev.key === 'Enter') {
      this.submit();
    }
  }

  onFocus = () => {
    this.setState({ focused: true });
  }
  onBlur = () => {
    this.setState({ focused: false });
  }

  formatOptionLabel = ({ label, value }) => {
    return (
      <div css={tw`text-sm text-grey-darkest`}>
        <span className={`flag-icon flag-icon-${value}`} css={tw`mr-2`} />
        <span>{label}</span>
      </div>
    );
  }

  render() {
    const { focused } = this.state;

    return (
      <Container className="container">
        <StyledLink to="/">
          <Title>
            YTThumb
            <span css={[tw`uppercase`, { fontSize: '0.525em' }]}>Inteam Records</span>
          </Title>
        </StyledLink>

        <div css={tw`flex-1 relative pl-4`}>
          <div css={tw`flex`}>
            {/* eslint-disable-next-line */}
            {/* <label>
              <Toggle
                defaultChecked={mainStore.hidePrivate}
                onChange={mainStore.toggleHidePrivate}
              />
              <span>Hide Private & Unlisted</span>
            </label> */}
            <Switch
              // defaultChecked={!mainStore.hidePrivate}
              checked={!mainStore.hidePrivate}
              onChange={mainStore.toggleHidePrivate}
            />
            <span css={tw`text-sm ml-2 cursor-pointer`} onClick={mainStore.toggleHidePrivate}>Show private & unlisted</span>
          </div>
        </div>

        {/* <div css={tw`flex-1 relative`}>
          <span
            css={tw`absolute text-xs font-bold text-grey-dark`}
            style={{
              top: -16,
              opacity: focused ? 1 : 0,
              transition: 'opacity 0.1s ease'
            }}
          >
            Press enter to search
          </span>
          <input
            css={tw`
              bg-grey-lighter
              appearance-none
              border-2
              border-grey-lighter
              rounded
              w-full
              py-2
              px-3
              text-grey-darker
              leading-tight
              focus:outline-none
              focus:bg-white
              focus:border-brand
            `}
            // style={{
            //   paddingRight: 80
            // }}
            type="search"
            placeholder="Search for video..."
            autoComplete="off"
            value={store.keyword}
            onChange={this.onKeywordChange}
            onKeyPress={this.onKeyPress}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <button
            type="button"
            css={tw`bg-brand text-white text-xs font-bold py-1 px-2 rounded uppercase absolute z-10 hover:bg-black`}
            style={{
              height: 'calc(100% - 12px)',
              top: 6,
              right: 6,
              opacity: focused ? 1 : 0,
              transition: 'opacity 0.1s ease'
            }}
            onClick={this.submit}
          >
            Search
          </button>
        </div> */}
      </Container>
    );
  }
}

export default view(Header);
