// import { navigate } from 'gatsby';
import { store } from 'react-easy-state';
import axios from 'axios';
import _ from 'lodash';
import ls from 'store2';
import nprogress from 'nprogress';

const lsYTT = ls.namespace('ytthumbs');

const mainStore = store({
  // channelId: 'UCOrEH-SzmP_Nmipsk0yfzMg',

  oauth: null,
  tokenInfo: null,

  channel: null,

  keyword: '',
  countryCode: 'my',
  loading: false,
  queryString: null,

  videos: [],
  nextPageToken: null,
  pageInfo: null,

  hidePrivate: false,

  // async fetchChannelVideos(pageToken = null) {
  //   mainStore.loading = true;
  //   await fetchPage(pageToken);
  //   mainStore.loading = false;
  // },

  responseGoogle: async (response) => {
    const { tokenObj: oauth } = response;
    console.log(response);


    lsYTT('oauth', oauth);

    const tokenInfo = await axios.get('https://www.googleapis.com/oauth2/v1/tokeninfo', {
      params: {
        access_token: oauth.access_token,
      }
    }).then(res => res.data);

    lsYTT('tokeninfo', tokenInfo);

    mainStore.oauth = oauth;
    mainStore.tokenInfo = tokenInfo;

    mainStore.channel = null;
    mainStore.videos = [];
    mainStore.nextPageToken = null;
    mainStore.pageInfo = null;

    if (oauth) {
      mainStore.loadPage();
    }
  },

  loadChannelInfo: async () => {
    const { channel, oauth, tokenInfo } = mainStore;

    if (channel) return;

    const channelResult = await axios.get('https://www.googleapis.com/youtube/v3/channels', {
      params: {
        mine: true,
        part: 'contentDetails',
        // k:
      },
      headers: {
        Authorization: `${oauth.token_type} ${oauth.access_token}`
      }
    }).then(res => res.data.items[0]);

    mainStore.channel = channelResult;
  },

  loadPage: async (pageToken = null) => {
    mainStore.loading = true;
    nprogress.start();

    await mainStore.loadChannelInfo();

    const { channel, oauth, tokenInfo } = mainStore;

    const playlistItems = await axios.get('https://www.googleapis.com/youtube/v3/playlistItems', {
      params: {
        playlistId: channel.contentDetails.relatedPlaylists.uploads,
        part: 'contentDetails,status',
        maxResults: 30,
        pageToken,
        k: Date.now()
      },
      headers: {
        Authorization: `${oauth.token_type} ${oauth.access_token}`,
        'x-username': tokenInfo.email,
      }
    }).then(res => res.data);
    // console.log(playlistItems);

    const myVideoIds = _.map(playlistItems.items, 'contentDetails.videoId');

    const videos = await axios.get('https://www.googleapis.com/youtube/v3/videos', {
      params: {
        id: myVideoIds.join(','),
        part: 'statistics,contentDetails,snippet',
      },
      headers: {
        Authorization: `${oauth.token_type} ${oauth.access_token}`,
        'x-username': tokenInfo.email,
      }
    }).then(res => res.data.items);

    const myVideos = playlistItems.items.map((video) => {
      const { id, statistics, contentDetails, snippet } = _.find(videos, { id: video.contentDetails.videoId });

      return {
        ...video,
        videoId: id,
        snippet,
        contentDetails,
        statistics
      };
    });

    // console.log(myUploadsVideos);

    mainStore.videos = !pageToken ? myVideos : mainStore.videos.concat(myVideos);
    mainStore.nextPageToken = playlistItems.nextPageToken;
    mainStore.pageInfo = playlistItems.pageInfo;
    mainStore.loading = false;
    nprogress.done();
  },

  toggleHidePrivate: () => {
    mainStore.hidePrivate = !mainStore.hidePrivate;
  }
});

export default mainStore;
