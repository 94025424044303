import React from 'react';
// import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from '@emotion/styled';

import Header from './header';

import 'nprogress/nprogress.css';

import '../styles/index.tailwind.css';
import '../styles/main.css';

const Content = styled.div`
  ${tw`pb-8 font-sans text-black`};
`;

const Layout = ({ children }) => (
  <>
    <Helmet
      title="YTThumbs for Inteam"
      meta={[
        { name: 'description', content: 'YTThumbs for Inteam Records' },
        { name: 'keywords', content: 'youtube, thumbs' },
      ]}
    >
      <html lang="en" />
      {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.2.1/css/flag-icon.min.css" /> */}
      <link href="https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,700" rel="stylesheet" />
      <link rel="stylesheet" href="https://pro.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-LRlmVvLKVApDVGuspQFnRQJjkv0P7/YFrw84YYQtmYG4nK8c+M+NlmYDCv0rKWpG" crossOrigin="anonymous" />
    </Helmet>

    <Header />

    <Content className="container">{children}</Content>
  </>
);

// Layout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

export default Layout;
